$(function () {
  $(document).scroll(function () {
    var $nav = $(".cd-auto-hide-header");
    var $navLogo = $(".nav-logo");
    $nav.toggleClass('nav-scrolled', $(this).scrollTop() > $nav.height());
    $navLogo.toggleClass('nav-logo-small', $(this).scrollTop() > $nav.height());
  });
});


$(document).on('click', '#spec-tab a', function(e) {
  otherTabs = $(this).attr('data-secondary').split(',');
  for(i= 0; i<otherTabs.length;i++) {
    nav = $('<ul class="nav d-none" id="tmpNav"></ul>');
    nav.append('<li class="nav-item"><a href="#" data-toggle="tab" data-target="' + otherTabs[i] + '">nav</a></li>"');
    nav.find('a').tab('show');
  }
});



var mySwiper = new Swiper('.latest-news-swiper', {
    loop: false,
    slidesPerView: 2.5,
    spaceBetween: 50,
    breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 2.5,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        600: {
            slidesPerView: 1,
            spaceBetween: 20
        },
    },
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },
  // Navigation arrows
  navigation: {
    nextEl: '.latest-news-next',
    prevEl: '.latest-news-prev',
  }
})

var mySwiper = new Swiper('.news-swiper', {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 50,
    centeredSlides: true,
    breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 2.5,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        600: {
            slidesPerView: 1,
            spaceBetween: 20
        },
    },
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },
  // Navigation arrows
  navigation: {
    nextEl: '.news-next',
    prevEl: '.news-prev',
  }
})

var mySwiper = new Swiper('.portfolio-swiper-1', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next',
    prevEl: '.portfolio-swiper-prev',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})

var mySwiper = new Swiper('.plot-gallery-slider', {
  loop: false,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.plot-gallery-next',
    prevEl: '.plot-gallery-prev',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 1,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})

var mySwiper = new Swiper('.homepage-swiper', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.homepage-next',
    prevEl: '.homepage-prev',
  }
})

var mySwiper = new Swiper('.general-swiper', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.general-next',
    prevEl: '.general-prev',
  }
})

var mySwiper = new Swiper('.general-page-gallery', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.page-gallery-next',
    prevEl: '.page-gallery-prev',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        600: {
            slidesPerView: 1,
            spaceBetween: 20
        },
    },
})



var mySwiper = new Swiper('.other-plots-swiper', {
  loop: false,
  slidesPerView: 3,
  spaceBetween: 15,
  centeredSlides: true,
  navigation: {
    nextEl: '.plot-gallery-next',
    prevEl: '.plot-gallery-prev',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 1,
            spaceBetween: 20
        },
        600: {
            slidesPerView: 1,
            spaceBetween: 20
        },
    },
})

var mySwiper = new Swiper('.latest-awards-swiper', {
  loop: false,
  slidesPerView: 2.5,
  spaceBetween: 50,
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 2.5,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        600: {
            slidesPerView: 1,
            spaceBetween: 20
        },
    },
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },
  // Navigation arrows
  navigation: {
    nextEl: '.latest-awards-next',
    prevEl: '.latest-awards-prev',
  }
})

var mySwiper = new Swiper('.awards-logo-swiper', {
    loop: false,
    slidesPerView: 4,
    spaceBetween: 50,
    breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 4,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        600: {
            slidesPerView: 2,
            spaceBetween: 20
        },
    },
  navigation: {
    nextEl: '.award-logos-next',
    prevEl: '.award-logos-prev',
  }
})
$('.nav-toggler').on('click', function(){
  $('.slide-out-menu').toggleClass('slide-out-menu-active');
  $('body').toggleClass('sc-fixed');
})

var galleryThumbs = new Swiper('.gallery-thumbs', {
  spaceBetween: 15,
  slidesPerView: 3,
  loop: true,
  freeMode: false,
  loopedSlides: 5, //looped slides should be the same
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  navigation: {
    nextEl: '.gallery-slider-next-sm',
    prevEl: '.gallery-slider-prev-sm',
  },
});
var galleryTop = new Swiper('.gallery-top', {
  spaceBetween: 15,
  loop: true,
  loopedSlides: 5, //looped slides should be the same
  navigation: {
    nextEl: '.gallery-slider-next',
    prevEl: '.gallery-slider-prev',
  },
  thumbs: {
    swiper: galleryThumbs,
  },
});

$('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
  if (!$(this).next().hasClass('show')) {
    $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
  }
  var $subMenu = $(this).next('.dropdown-menu');
  $subMenu.toggleClass('show');


  $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
    $('.dropdown-submenu .show').removeClass('show');
  });
  return false;
});

$(".area-anchor").on('click', function() {
  console.log('test');
  $("#areaAnchor").get(0).scrollIntoView();
});



var mySwiper = new Swiper('.portfolio-swiper-1', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-1',
    prevEl: '.portfolio-swiper-prev-1',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-2', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-2',
    prevEl: '.portfolio-swiper-prev-2',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-3', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-3',
    prevEl: '.portfolio-swiper-prev-3',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-4', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-4',
    prevEl: '.portfolio-swiper-prev-4',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-5', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-5',
    prevEl: '.portfolio-swiper-prev-5',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})

var mySwiper = new Swiper('.portfolio-swiper-6', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-6',
    prevEl: '.portfolio-swiper-prev-6',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})

var mySwiper = new Swiper('.portfolio-swiper-7', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-7',
    prevEl: '.portfolio-swiper-prev-7',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-8', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-8',
    prevEl: '.portfolio-swiper-prev-8',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})

var mySwiper = new Swiper('.portfolio-swiper-9', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-9',
    prevEl: '.portfolio-swiper-prev-9',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})

var mySwiper = new Swiper('.portfolio-swiper-10', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-10',
    prevEl: '.portfolio-swiper-prev-10',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-11', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-11',
    prevEl: '.portfolio-swiper-prev-11',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-12', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-12',
    prevEl: '.portfolio-swiper-prev-12',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-13', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-13',
    prevEl: '.portfolio-swiper-prev-13',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-14', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-14',
    prevEl: '.portfolio-swiper-prev-14',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-15', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-15',
    prevEl: '.portfolio-swiper-prev-15',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-16', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-16',
    prevEl: '.portfolio-swiper-prev-16',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-17', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-17',
    prevEl: '.portfolio-swiper-prev-17',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-18', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-18',
    prevEl: '.portfolio-swiper-prev-18',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})
var mySwiper = new Swiper('.portfolio-swiper-19', {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  navigation: {
    nextEl: '.portfolio-swiper-next-19',
    prevEl: '.portfolio-swiper-prev-19',
  },
  breakpoints: {
        // when window width is >= 320px
        1200: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 0

        },
        600: {
            slidesPerView: 1,
            spaceBetween: 0
        },
    },
})

// $(".dev-video-modal").on('hidden.bs.modal', function (e) {
//     $(".dev-video-modal iframe").attr("src", $(".dev-video-modal iframe").attr("src"));
// });

$(function(){
    $('.dev-video-modal').modal({
        show: false
    }).on('hidden.bs.modal', function(){
      console.log('testq23');
        $(this).find('video')[0].pause();
    });
});

$(function(){
    $('[data-action=point-map-zoom-in]').on('click', function(event) {
        event.preventDefault();
        $('.point-map').attr('data-zoomed', 'true');
        $(this).attr('disabled', '');
        $('[data-action=point-map-zoom-out]').removeAttr('disabled');
    })
    
    $('[data-action=point-map-zoom-out]').on('click', function(event) {
        event.preventDefault();
        $('.point-map').attr('data-zoomed', 'false');
        $(this).attr('disabled', '');
        $('[data-action=point-map-zoom-in]').removeAttr('disabled');
    })
});